// import Head from 'next/head';
// import { PageTransition } from 'next-page-transitions'
// import { TweenMax, TimelineMax, Ease, Power4 } from 'gsap';
// import Menu from '../components/Menu/Menu';
// import BackgroundPattern from '../components/BackgroundPattern/BackgroundPattern';
import React, { Component, Fragment } from "react";
import PropTypes from 'prop-types';
import Stats from '../util/stats';
import detect from '../util/detect/index.js';
import settings from '../util/settings';
import analytics from '../util/analytics';
import styles from './style/main.module.scss';

export default class Page extends Component {
  constructor(props){
    super(props);
    if (!settings.isServer()) {
      this.analytics = analytics.setup();
    }
  }

  componentDidMount() {
    // if (settings.isDev) Stats();
    detect.init();
    document.body.className = [...new Set([...document.body.className.split(' '), ...detect.classes()])].join(' ');
    
    this.analytics.pageview(window.location.pathname)
  }

  render() {
    const { id, children, menu, showMenu = true } = this.props;
    return (
      <main id={id || ""} className={styles['page-layout']}
        ref={e => this.transitionContainer = e}
      >
        { children }
      </main>
    )
  }
}

Page.propTypes = {
  id: PropTypes.string,
  children: PropTypes.array,
  className: PropTypes.string,
  menu: PropTypes.object
}

Page.defaultProps = {
  id: '',
  children: [],
  className: '',
  menu: {}
}